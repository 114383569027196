import React from 'react'
import IconCall from '../icons/IconCall'
import CryptoJS from 'crypto-js'
function HeaderDex(props) {
  const secretPass2 = "kldsklfjddfdsdlkdfkkldfjkf";
 
    if (props.item==='cart'){
  return (
    <li children='Dex_y10'><div style={{position: 'relative'}}>
    <div>
            <div className='Dex_y11'>
                <a className='Dex_y12'>
                    <span className='Dex_y13'><IconCall width={25} height={25} color={'#5985E1'}  icon={'cart'} /> <div className='Dex_y16'><span className='Dex_y14'>0</span></div></span>
                    <span>cart</span>
                   
                    </a></div></div>
     </div></li>
  )} else if (props.item==='search'){
    return (
      <li children='Dex_y10' onClick={()=>props.ShowSearchBar(true)}><div style={{position: 'relative'}}>
      <div>
              <div className='Dex_y11'>
                  <a className='Dex_y12'>
                      <span className='Dex_y13'><IconCall width={25} height={25} color={'#5985E1'}  icon={'search'} /> </span>
                      <span>search</span>
                     
                      </a></div></div>
       </div></li>
    )} else if (props.item==='login'){
        return (
          <li children='Dex_y10' onClick={()=>props.Login(true)}><div style={{position: 'relative'}}>
          <div>
                  <div className='Dex_y11'>
                      <a className='Dex_y12'>
                          <span className='Dex_y13'><IconCall width={25} height={25} color={'#5985E1'}  icon={'login'} /></span>
                          <span style={{  maxWidth: '9ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}> {sessionStorage.getItem('uDnE')?CryptoJS.AES.decrypt(sessionStorage.getItem('uDnE'), secretPass2).toString(CryptoJS.enc.Utf8):'login'}</span>
                         
                          </a></div></div>
           </div></li>
        )}
        else if (props.item==='contactUs'){
            return (
              <li children='Dex_y10'><div style={{position: 'relative'}}>
              <div>
                      <div className='Dex_y11'>
                          <a className='Dex_y12'>
                              <span className='Dex_y13'><IconCall width={25} height={25} color={'#5985E1'}  icon={'contactUs'} /></span>
                              <span>Help</span>
                             
                              </a></div></div>
               </div></li>
            )}
}

export default HeaderDex
