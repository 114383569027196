import React,{useState,useEffect, useRef, useMemo} from 'react'
import { useGeolocated } from "react-geolocated";
import axios  from 'axios';
import { useMediaQuery } from 'react-responsive';
const Map=(props)=>{
const [AreaPlusRange,SetAreaPlusRange]=useState()

  const SetCustomPeramiter=props.SetCustomPeramiter
  const SetMyLocation=props.SetMyLocation
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  var req=''
const [updateAdress,updatedAdress]=useState()

const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  useGeolocated({
      positionOptions: {
          enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
  });
 

  




if(coords && !req){
  


 req= axios.get('./getmylocation',{
  params: {lat:coords.latitude,
  lng:coords.longitude}})
    .then(response => { 
      localStorage.setItem('Lodsjdfoer', JSON.stringify(response))
      localStorage.setItem('fetchStatusOk',true)
      sessionStorage.setItem('timeOfLastActive',new Date()/1)
      sessionStorage.setItem('userLocation',response.data.results[0].address_components[2].long_name)
      sessionStorage.setItem('userLocationPlusCode',response.data.plus_code.compound_code)
    SetAreaPlusRange(response.data.plus_code.compound_code)
    sessionStorage.setItem('LocationUpdated','GEO');
      props.StatusLocation(true)     
     console.log(updateAdress)
       })
        .catch(error => {
          if(SetMyLocation){
            props.SetMyLocation(false)}
            if(SetMyLocation){
            SetCustomPeramiter('Some wrong connections may cause errors! Please try again')}
   sessionStorage.setItem('error',error)
    });}    
    if(!isGeolocationEnabled){
     if(SetMyLocation){
  SetMyLocation(false)}
  if(SetCustomPeramiter){
      SetCustomPeramiter('Access Denied! Do enable GPS to get current location')
   } }
    if(!isGeolocationAvailable){
      if(SetMyLocation){
      props.SetMyLocation(false)}
      if(SetMyLocation){
      SetCustomPeramiter('Error!Please Try Again Later')}
    } 
return(
   <>{AreaPlusRange} </>)


}


export default Map;
