import React, { useRef, useEffect, useState } from 'react';
import "../style-item/popupLocation.css"
import { useMediaQuery } from 'react-responsive';
import GoogleMapReact from 'google-map-react';
import IconCall from '../icons/IconCall';
import axios from 'axios';


function PopupLocation(props) {

   
const mapStyle= [
  {
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "color": "#d9a412"
      },
      {
        "weight": 6.5
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#523735"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#c9b2a6"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#dcd2be"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ae9e90"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#93817c"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "weight": 8
      }
    ]
  },
  {
    "featureType": "poi.government",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#a5b076"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#447530"
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.sports_complex",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#fdfcf8"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f8c967"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#e9bc62"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e98d58"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#db8555"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#806b63"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8f7d77"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#ebe3cd"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels",
    "stylers": [
      {
        "color": "#260a3d"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "color": "#002a57"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#388ce5"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#260a3d"
      },
      {
        "weight": 5
      }
    ]
  }
]
const [RestPonseStore,SetRes]=useState()
const FunXL=()=>{
  
return (<>{RestPonseStore? <div className='ghg6fEY' onClick={()=>{localStorage.setItem('Lodsjdfoer', JSON.stringify(RestPonseStore)); 
    sessionStorage.setItem('timeOfLastActive',new Date()/1);
    sessionStorage.setItem('userLocation',RestPonseStore.data.plus_code.global_code);
    sessionStorage.setItem('userLocationPlusCode',RestPonseStore.data.plus_code.compound_code);
    sessionStorage.setItem('LocationUpdated','MAP'); props.ShowPopResetLocation(false);}}> <div className='gpsicon'><IconCall icon={'GPS'}/></div>
  <div className='ssdfe'>
    <div className='jklfdkldf'>{RestPonseStore.data.plus_code.compound_code}</div>
    <div className='dfkljd' >Click To Confirm</div>
  </div>
  </div>:<div className='ghg6fEY'> <div className='gpsicon'><IconCall icon={'GPS'}/></div>
  <div className='ssdfe'>
    <div className='jklfdkldf'>Loading...</div>
    <div className='dfkljd' >Wait a moment</div>
  </div>
  </div>}</>)
   
  
}
const DataLocationFetch=()=>{
  
 }
const [Latitude,SetLatitude]=useState(0)
const [Longitude,SetLongitude]=useState(0)
const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const handlePlaceSelected = (place) => {
        const location = place.geometry.location;
     SetLatitude(location.lat())
     SetLongitude(location.lng())
     ShowPopMap(true)
     axios.get('./getmylocation',{
      params: {lat:location.lat(),
      lng:location.lng()}}) .then(response => {
  SetRes(response)  })  .catch(error => {
    sessionStorage.setItem('error',error)})
}
const latLng=(e)=>{
  SetLatitude(e.lat)
  SetLongitude(e.lng)
  axios.get('./getmylocation',{
    params: {lat:e.lat,
    lng:e.lng}}) .then(response => {
SetRes(response)  })  .catch(error => {
  sessionStorage.setItem('error',error)})
      };

      
      const [popMap,ShowPopMap]=useState(false)
const Autocomplete = ({ onPlaceSelected }) => {
    const inputRef = useRef(null);
    useEffect(() => {
     const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
      });
    }, []);
  
    return <div className='sdfiid'><div className='dfgd3gg'><input className='sdsfkj' onChange={()=>ShowPopMap(false)} ref={inputRef}  id="pac-input" autoFocus class="controls" type="text" style={{zIndex: '1500000002'}} placeholder="Search Area Name" />
  </div></div>}
  return (
    <div class="xxxx">
    <div class="alert">
        <span class="closebtn" onClick={()=> props.ShowPopResetLocation(false)}><IconCall icon={'cross'} width={24} height={24}/></span>
        Want To Reset My Location !
    </div><div class="locationEdge">
      
{popMap&&   <div className='xxxx' style={{maxWidth:'480px',height:'300px',    position:'relative'}}> <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCRP5bkvEVfRWqHDN1ZUTzLl8XKcLTWKsA" }}
        defaultCenter={{lat:Latitude,lng:Longitude}}
        defaultZoom={15}
        style={{
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "red"
            }
          ]
        }}
        onClick={(e)=>latLng(e)}
        maxZoom={15}
        options={{
          scrollwheel: false,
          streetViewControl: false,
          maxZoom:15,
          styles: mapStyle,zoomControl:false
      }}
       
        distanceToMouse={(x)=>console.log(x)}
   
      >

         
     <AnyReactComponent
          lat={Latitude-0.003}
          lng={Longitude-0.001}
      
          text={<IconCall icon={'pointer'} height={44} width={44}/>}
          
        /></GoogleMapReact>
       
        </div>

}
        <Autocomplete onPlaceSelected={handlePlaceSelected}></Autocomplete>
        {popMap&&<FunXL/>}
  {!popMap&&<div  onClick={()=>{
    localStorage.removeItem('Lodsjdfoer');
    sessionStorage.removeItem('LocationUpdated');
    sessionStorage.removeItem('timeOfLastActive');
    sessionStorage.removeItem('userLocation');
    sessionStorage.removeItem('userLocationPlusCode');
    props.SetMyLocation(true);props.ShowPopResetLocation(false)}} className='ghg6fEY'> <div className='gpsicon'><IconCall icon={'GPS'}/></div>
  <div className='ssdfe'>
    <div className='jklfdkldf'>Get Your Location</div>
    <div className='dfkljd'>Via GPS</div>
  </div>
  </div> }
 { !popMap&&<div  onClick={()=>{props.ShowMap(true);props.ShowPopResetLocation(false)}} className='ghg6fEY'> <div className='gpsicon'><IconCall icon={'MapIc'}/></div>
  <div className='ssdfe'>
    <div className='jklfdkldf'>Set Location</div>
    <div className='dfkljd'>Via Map</div>
  </div>
  </div>}
  </div>
  
  </div>
  )
}

export default PopupLocation
