import React, { useState } from 'react'
import { useRef } from 'react'
import '../style-item/SPL.css'
import IconCall from '../icons/IconCall'
function SPL(props) {
  const [searchText,setSearchText]=useState({
    search:''
  })
  const [Recomendation,NextRecomendation]=useState(false)
  const InputFocus=(q)=>{

      const {name,value}= q.target;
      console.log(value)
      setSearchText({...searchText,[name]:value});
    (value>2)?!Recomendation ? NextRecomendation(true):NextRecomendation(false):Recomendation ?NextRecomendation(false):NextRecomendation(true)
  }
  const SerchNv=()=>{
    return (<div className='SPL3'>
            <span className='SPL4' onClick={()=>props.ShowSearchBar(false)}>
                <IconCall icon={'ArowLeft'}/>
            </span>
            <input className='SPL5' name='search' autoFocus value={searchText.search} onChange={InputFocus} placeholder='Search Item By Name'/>
        </div>)
  }
  const SrchRec=()=>{
return(  <div className='SPL7'><div className='SPL8'>
          <div className='SPL9'>Recomendation For You</div>
          <div className='SPL10'>
            <div className='SPL11'>
              <a className='SPL12'>
                <div><div className='SPL13'>GDE D</div></div>
              </a>
            </div>
          </div>
          </div></div>)
  }
  const InputRec=()=>{
    return (  <ul className='SPL14'>
      <li className='SPL15'>
        <a className='SPL16'> <div className='SPL17'>
        <div className='SPL18'><img className='SPL19' src='srcset="https://rukminim1.flixcart.com/image/100/40/k612pow0/valentine-gift-set/4/m/n/qys-48-surprise-message-in-bottle-for-wife-girlfriend-fiance-on-original-imafnfxhgajqcnts.jpeg?q=80 1x, https://rukminim1.flixcart.com/image/200/80/k612pow0/valentine-gift-set/4/m/n/qys-48-surprise-message-in-bottle-for-wife-girlfriend-fiance-on-original-imafnfxhgajqcnts.jpeg?q=60 2x, https://rukminim1.flixcart.com/image/300/120/k612pow0/valentine-gift-set/4/m/n/qys-48-surprise-message-in-bottle-for-wife-girlfriend-fiance-on-original-imafnfxhgajqcnts.jpeg?q=40 3x, '/>
       </div>
        </div>
         <div className='SPL20'><div className='SPL21'><span className='SPL22'>kldfKD</span></div>
        <div className='SPL23'>DeMo</div>
        </div><div><div className='SPL24'><IconCall icon={'Chk'}/></div></div></a></li>
    </ul>)
  }
  const Tol=()=>{
    return ( <div className='SPL1'>
      <div className='SPL2'>
        <SerchNv/>
        <div className='SPL6'>
        {!Recomendation&& <SrchRec/>}
        {Recomendation&&<InputRec/>}
          </div>
      </div>
    </div>)
  }
  return (<div><div><div className='SPL diDHph' style={{position:'fixed',zIndex:'1500000000',    opacity: '1.3'}}>
    <Tol/>
   </div></div></div>
  )
}

export default SPL
