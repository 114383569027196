import React from 'react'
import '../style-item/locPer.css'
import IconCall from '../icons/IconCall'

function Locpermiss(props) {
    const CustomPeramiter=props.CustomPeramiter
    const GetMyLocation=props.GetMyLocation
  
   
  return (
    <div className='diIKd-Kidfi0dn_oso'>
      <div className='styles_container__sMiSq top-[4rem] after:!border-none'>
        <div className='poinM'>
           
                <IconCall className={'markPoint'} icon={'makerPointer'}/>
           
        </div>
        <div className='sdiop-Pd12'>
            <p className='bser1z tasopjwISD !1425g-[0.5px]'>{!CustomPeramiter&&<>Enable location permission to get faster delivery</>}{CustomPeramiter&&<>{CustomPeramiter}</>}</p>
            <div className='fdJdje'>{!props.GetMyLocation? <button className='menualBtn' onClick={()=>props.ShowMap(true)}><div className='sdfIej15'><p className='bser1z'>Set manually</p></div></button>:<button className='menualBtn djYUE2' ><div className='sdfIej15'><p className='bser1z'>Set manually</p></div></button>}
           {!props.GetMyLocation&& <button className='menualBtn djYUE1' onClick={()=>props.SetMyLocation(true)}><div className='sdfIej15'><p className='bser1z'>Get My Location</p></div></button>}
          { props.GetMyLocation&& <button className='menualBtn djYUE2'><div className='sdfIej15'><p className='bser1z'>Getting Location</p></div></button>}
            </div>
        </div>
        
      </div>
    </div>
  )
}

export default Locpermiss
