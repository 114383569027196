import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RoutFun from './RoutFun';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="1048113087992-fa64bjkap2d10mqkskqrgvkq2cd1spt2.apps.googleusercontent.com">
    <RoutFun/></GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
