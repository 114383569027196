import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './component/App';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Agent from './deliver/sent'
import Mysdk from './SDK/mysdk';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));


function RoutFun() {
  const secretPass1 = "sdjkljlsdlkJKjksdlkseOEjd";
  const secretPass2 = "kldsklfjddfdsdlkdfkkldfjkf";
  const secretPass4 = "fjdkdfkljgldfguitytlkseOEjd";
  const secretPass3 = "kfg6tythvcbdffgdfhgfhffjkf";
  const secretPass5 = "sdjkljlsdldfdrtdfdgfgghfEjd";

    const [AuthToken,SetAuthToken]=useState(sessionStorage.getItem('uDtE')?sessionStorage.getItem('uDtE'):null)
    const [AuthUserName,SetAuthUserName]=useState(sessionStorage.getItem('uDnE')?true:null)
    const [AuthUserId,SetAuthUserId]=useState(sessionStorage.getItem('uDeE')?true:null)
  return (
 
<Agent.Provider prfUn={AuthUserName} prfULd={AuthUserId} value={AuthToken}>

<BrowserRouter>
<Routes>
<Route path="/success" element={ <App SuccessNote={true} AllPathAccess={true}/>} />
<Route path="/" element={ <App AllPathAccess={true}/>} />
<Route path="/login" element={AuthToken? <App loginReq={false}/>:<App loginReq={true}/>} />
</Routes>
</BrowserRouter>
</Agent.Provider>
  )
}

export default RoutFun
