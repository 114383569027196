import React from 'react'
import '../style-item/menu.css'
import IconCall from '../icons/IconCall'
function Menu(props) {
function Items1(){
    return(  <a className='MOrdH1' onClick={()=>props.SetMenu('home')}>
        <div className='sYfhe_4-63d'>
            <IconCall  ShowMenu={props.ShowMenu} SetMenu={props.SetMenu} icon={'home'} className={'IcsSwUsrM'}/>
            {props.ShowMenu==='home'?<p className='ClsTgNa'  style={{color:'green'}}>HOME</p>:<p className='ClsTgNa'>HOME</p>}
        </div>
      </a>)
}
function Items2(){
    return(  <a className='MOrdH1' onClick={()=>props.SetMenu('category')}>
        <div className='sYfhe_4-63d'>
            <IconCall  ShowMenu={props.ShowMenu} SetMenu={props.SetMenu} icon={'category'} className={'IcsSwUsrM'}/>
              {props.ShowMenu==='category'?<p className='ClsTgNa' style={{color:'green'}}>Category</p>:<p className='ClsTgNa' >Category</p>}
        </div>
      </a>)
}

function Items3(){
    return(  <a className='MOrdH1'  onClick={()=>props.SetMenu('Mcart')}>
        <div className='sYfhe_4-63d'>
            <IconCall  ShowMenu={props.ShowMenu} SetMenu={props.SetMenu} icon={'Mcart'} className={'IcsSwUsrM'}/>
          {props.ShowMenu==='Mcart'?<p className='ClsTgNa' style={{color:'green'}}>Cart</p>:<p className='ClsTgNa' >Cart</p>}  
        </div>
      </a>)
}

function Items4(){
    return(  <a className='MOrdH1'  onClick={()=>props.SetMenu('orders')}>
        <div className='sYfhe_4-63d'>
            <IconCall icon={'orders'}  ShowMenu={props.ShowMenu} SetMenu={props.SetMenu}  className={'IcsSwUsrM'}/>
           { props.ShowMenu==='orders'?<p className='ClsTgNa' style={{color:'green'}}>orders</p>:<p className='ClsTgNa' >orders</p>}
        </div>
      </a>)
}
  return (
    <div className='fixed bottom-0 z-[1000] grid w-full border-t bg-white px-1.5 pb-3.5 shadow-[rgba(0,0,15,0.05)_0px_-5px_4px_0px] lg:hidden' id='munu'>
    <Items1/>
    <Items2/>
    <Items3/>
    <Items4/>
    </div>
  )
}

export default Menu
