import React from 'react'

function IconCall(props) {
    if(props.icon==='pointer'){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={props.height} viewBox="0 -960 960 960" width={props.width} fill="#FF0011"><path d="M480-360q56 0 101-27.5t71-72.5q-35-29-79-44.5T480-520q-49 0-93 15.5T308-460q26 45 71 72.5T480-360Zm0-200q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0 374q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>
  )}else if(props.icon==='user'){
    return (<svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="white"><path d="M608-522 422-708q14-6 28.5-9t29.5-3q59 0 99.5 40.5T620-580q0 15-3 29.5t-9 28.5ZM234-276q51-39 114-61.5T480-360q18 0 34.5 1.5T549-354l-88-88q-47-6-80.5-39.5T341-562L227-676q-32 41-49.5 90.5T160-480q0 59 19.5 111t54.5 93Zm498-8q32-41 50-90.5T800-480q0-133-93.5-226.5T480-800q-56 0-105.5 18T284-732l448 448ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-60Z"/></svg>)
  }else if(props.icon==='verified-user'){
    return (<svg xmlns="http://www.w3.org/2000/svg"  height={props.height} viewBox="0 -960 960 960"  width={props.width} fill="white"><path d="M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31 0 57-14.5t42-38.5q-22-13-47-20t-52-7q-27 0-52 7t-47 20q16 24 42 38.5t57 14.5ZM480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v227q-19-8-39-14.5t-41-9.5v-147l-240-90-240 90v188q0 47 12.5 94t35 89.5Q310-290 342-254t71 60q11 32 29 61t41 52q-1 0-1.5.5t-1.5.5Zm200 0q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80ZM480-494Z"/></svg>)
  }else if(props.icon==='close'){
    return (<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#B7B7B7"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>)
  }
  else if(props.icon==='customPointer'){
    return (<svg xmlns="http://www.w3.org/2000/svg"  height={props.height}  viewBox="0 -960 960 960" width={props.width}  fill={props.color}><path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm0-80Z"/></svg>)
  }
  else if(props.icon==='cart'){
    return (<svg xmlns="http://www.w3.org/2000/svg" className='Dex_y15' height={props.height} viewBox="0 -960 960 960"  width={props.width}  fill={props.color}><path d="M240-80q-33 0-56.5-23.5T160-160v-480q0-33 23.5-56.5T240-720h80q0-66 47-113t113-47q66 0 113 47t47 113h80q33 0 56.5 23.5T800-640v480q0 33-23.5 56.5T720-80H240Zm0-80h480v-480h-80v80q0 17-11.5 28.5T600-520q-17 0-28.5-11.5T560-560v-80H400v80q0 17-11.5 28.5T360-520q-17 0-28.5-11.5T320-560v-80h-80v480Zm160-560h160q0-33-23.5-56.5T480-800q-33 0-56.5 23.5T400-720ZM240-160v-480 480Z"/></svg>)
  }
  else if(props.icon==='search'){
    return (<svg xmlns="http://www.w3.org/2000/svg" className='Dex_y15' height={props.height} viewBox="0 -960 960 960"  width={props.width}  fill={props.color}><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>)
  } else if(props.icon==='login'){
    return (<svg xmlns="http://www.w3.org/2000/svg" className='Dex_y15' height={props.height} viewBox="0 -960 960 960"  width={props.width}  fill={props.color}><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/></svg>)
  } else if(props.icon==='contactUs'){
    return (<svg xmlns="http://www.w3.org/2000/svg" className='Dex_y15' height={props.height} viewBox="0 -960 960 960"  width={props.width}  fill={props.color}><path d="m480-80-10-120h-10q-142 0-241-99t-99-241q0-142 99-241t241-99q71 0 132.5 26.5t108 73q46.5 46.5 73 108T800-540q0 75-24.5 144t-67 128q-42.5 59-101 107T480-80Zm80-146q71-60 115.5-140.5T720-540q0-109-75.5-184.5T460-800q-109 0-184.5 75.5T200-540q0 109 75.5 184.5T460-280h100v54Zm-101-95q17 0 29-12t12-29q0-17-12-29t-29-12q-17 0-29 12t-12 29q0 17 12 29t29 12Zm-29-127h60q0-30 6-42t38-44q18-18 30-39t12-45q0-51-34.5-76.5T460-720q-44 0-74 24.5T344-636l56 22q5-17 19-33.5t41-16.5q27 0 40.5 15t13.5 33q0 17-10 30.5T480-558q-35 30-42.5 47.5T430-448Zm30-65Z"/></svg>)
  }else if(props.icon==='facebook')
{
  return (<><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" viewBox="0 0 93 93" enable-background="new 0 0 93 93" space="preserve"><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M53.000000,94.000000 
     C49.637897,94.000000 46.275799,94.000000 42.360603,93.792267 
     C41.633583,93.321983 41.400383,93.132339 41.073418,92.553200 
     C40.996292,88.063339 40.953659,84.035858 40.945587,79.540359 
     C40.635811,73.069054 40.291470,67.065765 39.696114,60.993881 
     C39.279831,60.872547 39.114559,60.819817 38.658581,60.529442 
     C36.578178,60.196259 34.788483,60.100712 32.606400,59.997990 
     C31.170496,59.976257 30.126978,59.961704 29.017239,60.008347 
     C28.951021,60.069542 28.771286,60.054897 28.917444,59.790115 
     C29.138594,59.338116 29.213587,59.150898 29.452682,58.844505 
     C29.811081,58.525738 29.940891,58.290539 30.004650,57.678299 
     C29.668089,56.551769 29.333096,55.766674 28.906120,54.560043 
     C28.671793,52.440067 28.529451,50.741627 28.551203,48.789211 
     C28.818043,48.361813 28.920788,48.188381 29.392834,48.026939 
     C30.236250,47.916435 30.710367,47.793941 31.392593,47.732452 
     C31.600702,47.793453 31.987896,47.988907 32.078056,48.161335 
     C32.440010,48.205257 32.711800,48.076748 32.878197,47.903992 
     C32.772804,47.859745 32.815514,47.635166 33.112663,47.769913 
     C34.025600,47.783966 34.641396,47.663269 35.603085,47.566086 
     C36.862541,47.487255 37.776108,47.384911 38.774384,47.457512 
     C38.859097,47.632458 39.073826,47.957203 38.915764,47.997791 
     C38.850571,48.008999 38.943443,47.979622 39.270168,47.823738 
     C39.759602,47.452667 40.015182,47.208103 40.534378,46.649174 
     C40.840187,41.871277 40.882374,37.407742 40.961418,32.583626 
     C41.340359,30.829111 41.682442,29.435175 42.209320,28.001045 
     C42.394112,27.960850 42.769367,27.913546 43.149284,27.937328 
     C45.918392,27.746557 46.741470,26.893160 44.864815,24.615587 
     C45.049278,23.945679 45.145733,23.562786 45.187141,23.156544 
     C45.132095,23.133192 45.178558,23.243385 45.465698,23.225254 
     C46.453125,22.591311 47.153419,21.975500 47.887550,21.302572 
     C47.921387,21.245455 47.789787,21.227814 47.858398,21.414064 
     C48.190914,21.650585 48.446804,21.727518 49.028656,21.895037 
     C50.429836,21.979202 51.497044,21.999441 52.564255,22.019680 
     C52.133465,21.694427 51.702675,21.369173 51.284798,20.851112 
     C51.297703,20.658304 51.318241,20.272375 51.654381,20.209015 
     C52.890507,20.001432 53.790493,19.857210 55.140564,19.836700 
     C57.729328,20.053257 59.868000,20.146099 62.003868,20.528374 
     C62.004349,21.207048 62.007633,21.596291 61.942955,22.341835 
     C60.030884,23.723455 58.186783,24.748775 55.550423,26.214590 
     C58.314571,26.979584 60.156998,27.489487 61.999710,27.999695 
     C62.000000,28.000000 62.000610,28.000555 62.000450,28.365307 
     C61.998837,29.459564 61.997383,30.189068 61.712166,30.973557 
     C60.968910,31.321568 60.509418,31.614594 59.817207,32.030052 
     C59.379185,32.428776 59.173885,32.705074 58.660431,32.981476 
     C57.862888,33.238159 57.373505,33.494743 56.663139,33.830776 
     C56.442165,33.910225 55.981770,34.003006 55.736076,34.150169 
     C55.364799,34.649433 55.239220,35.001530 55.152931,35.634209 
     C55.095440,36.277378 54.998657,36.639965 54.693985,37.111961 
     C54.236702,37.433319 54.081665,37.700119 54.009090,38.013882 
     C53.997192,38.006001 53.975937,37.986954 53.729141,37.991688 
     C53.482346,37.996422 52.988205,38.002403 52.701355,38.145176 
     C51.940758,39.190754 51.467014,40.093559 50.650749,41.054565 
     C50.308235,43.964943 50.308235,46.817116 50.308235,50.925323 
     C52.268616,48.848831 53.146877,47.918552 54.301491,46.978367 
     C54.760044,46.938625 54.942242,46.908787 55.323051,46.928020 
     C55.521660,46.977093 55.910324,47.104973 55.949692,47.299603 
     C55.989056,47.494232 56.057789,47.885788 56.233894,48.172459 
     C57.273178,48.641319 58.136360,48.823509 59.254242,49.280571 
     C61.347145,49.032188 63.185349,48.508942 65.258751,47.915337 
     C65.493950,47.844986 65.962578,47.991497 66.079224,48.213921 
     C66.472954,48.612915 66.750038,48.789482 67.255386,48.837166 
     C67.639168,48.628555 67.794685,48.548836 67.692596,48.849499 
     C67.280838,49.820698 67.126678,50.411514 66.737808,51.167641 
     C66.542313,52.196419 66.581528,53.059883 66.674133,54.281036 
     C66.701904,55.361492 66.676277,56.084259 66.482544,56.814964 
     C66.314430,56.822895 65.988342,56.909725 65.726807,56.816864 
     C64.999962,59.058262 63.882721,60.346565 61.196663,60.073799 
     C59.850128,59.937057 58.425251,60.571819 56.750023,60.952042 
     C56.064034,61.076523 55.663830,61.106628 54.928848,61.281960 
     C54.407452,62.616314 54.220833,63.805443 54.018307,65.329239 
     C53.985699,66.110352 53.969002,66.556793 53.577049,67.013519 
     C52.497269,67.727402 51.792747,68.431007 51.088226,69.134605 
     C52.047756,69.755646 53.007286,70.376678 53.983356,71.414375 
     C53.989811,73.221443 53.979721,74.611855 53.584442,76.013947 
     C52.483887,77.056778 51.768517,78.087936 51.053146,79.119087 
     C52.016006,79.750893 52.978867,80.382698 53.959610,81.010269 
     C53.977489,81.006035 54.014317,80.997047 54.007656,81.457977 
     C53.981941,85.615135 53.962887,89.311371 53.736553,93.119781 
     C53.273067,93.432961 53.096642,93.688980 53.000000,94.000000 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M61.999428,27.999390 
     C60.156998,27.489487 58.314571,26.979584 55.550423,26.214590 
     C58.186783,24.748775 60.030884,23.723455 62.271011,22.344017 
     C63.104774,21.990101 63.542522,21.990301 64.272110,22.114738 
     C65.355972,22.490318 66.147995,22.741667 66.961853,23.367104 
     C67.003334,24.489431 67.022972,25.237673 67.031998,26.392502 
     C66.993080,28.146208 66.964767,29.493324 66.619736,30.903330 
     C65.876289,31.021938 65.449547,31.077658 64.645996,31.088568 
     C63.511429,31.002029 62.753681,30.960300 61.995930,30.918571 
     C61.997383,30.189068 61.998837,29.459564 62.283188,28.329567 
     C62.886990,27.625467 63.207893,27.321857 63.528797,27.018248 
     C63.320545,26.837954 63.112289,26.657660 62.904037,26.477365 
     C62.602501,26.984709 62.300964,27.492050 61.999428,27.999390 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M54.025139,46.988274 
     C53.146877,47.918552 52.268616,48.848831 50.308235,50.925323 
     C50.308235,46.817116 50.308235,43.964943 50.819710,41.298573 
     C51.885258,41.655678 52.439331,41.826977 53.091843,42.207771 
     C53.365643,42.682331 53.600166,42.875576 53.926102,43.395309 
     C53.980610,44.858505 54.002876,45.923389 54.025139,46.988274 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M43.149284,27.937328 
     C42.857719,27.492929 42.946072,27.072311 43.232082,26.184399 
     C43.429741,25.717100 43.527706,25.301788 43.736301,25.225178 
     C44.185867,24.943672 44.463173,24.861685 44.776810,24.902603 
     C46.741470,26.893160 45.918392,27.746557 43.149284,27.937328 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M52.993401,41.998276 
     C52.439331,41.826977 51.885258,41.655678 51.162224,41.240372 
     C51.467014,40.093559 51.940758,39.190754 52.705383,38.544708 
     C52.995308,39.867069 52.994354,40.932674 52.993401,41.998276 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M55.140564,19.836700 
     C54.690479,19.712988 54.742668,19.345531 55.084606,19.175791 
     C55.613373,18.969967 55.800198,18.933884 56.390083,18.945049 
     C57.871765,18.944241 58.950382,18.896179 60.296669,18.908012 
     C61.071510,19.141514 61.578690,19.315125 62.091568,19.678978 
     C62.097271,19.869221 62.006676,20.238943 62.006676,20.238943 
     C59.868000,20.146099 57.729328,20.053257 55.140564,19.836700 
    z"></path><path fill="#178CD7" opacity="1.000000" stroke="none" d="
    M48.102318,19.956083 
     C48.071018,20.190941 48.039722,20.425797 47.931068,21.010170 
     C47.153419,21.975500 46.453125,22.591311 45.465698,23.225254 
     C45.584187,22.250357 45.989811,21.257326 46.972672,20.115242 
     C47.734047,19.962818 47.918182,19.959450 48.102318,19.956083 
    z"></path>
    <path fill="#D1FCFF" opacity="1.000000" stroke="none" d="
    M35.973743,61.213020 
     C35.545906,61.182941 35.118073,61.152866 34.099854,61.095509 
     C33.324593,61.097977 33.139721,61.127724 32.998699,61.076637 
     C33.042545,60.995800 33.053818,60.812222 33.034576,60.610832 
     C33.015335,60.409439 32.998783,60.005165 32.998783,60.005165 
     C34.788483,60.100712 36.578178,60.196259 38.675274,60.651707 
     C38.982670,61.011612 39.088829,61.232765 38.827515,61.190010 
     C38.393047,61.181568 38.219891,61.215885 37.705223,61.162872 
     C36.900391,61.121368 36.437065,61.167194 35.973743,61.213020 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M48.841530,20.931992 
     C49.016102,20.916363 49.190670,20.900736 49.960976,20.918161 
     C50.795109,20.982113 51.033497,21.013016 51.271889,21.043919 
     C51.702675,21.369173 52.133465,21.694427 52.564255,22.019680 
     C51.497044,21.999441 50.429836,21.979202 49.063484,21.669979 
     C48.764343,21.380993 48.841530,20.931992 48.841530,20.931992 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M64.981415,31.841789 
     C63.608765,31.895021 62.236115,31.948254 60.456696,31.954552 
     C60.509418,31.614594 60.968910,31.321568 61.712166,30.973557 
     C62.753681,30.960300 63.511429,31.002029 64.635284,31.265202 
     C65.001381,31.486643 64.981415,31.841791 64.981415,31.841789 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M53.091843,42.207771 
     C52.994354,40.932674 52.995308,39.867069 52.992233,38.401932 
     C52.988205,38.002403 53.482346,37.996422 53.729141,37.991688 
     C53.972885,39.379917 53.969833,40.772877 53.930321,42.581421 
     C53.600166,42.875576 53.365643,42.682331 53.091843,42.207771 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M65.824188,60.867653 
     C65.894821,59.813560 65.965454,58.759468 66.012215,57.307549 
     C65.988342,56.909725 66.314430,56.822895 66.482544,56.814964 
     C66.650650,56.807030 66.961967,56.906715 67.034286,57.364990 
     C67.066933,58.873814 67.027267,59.924362 66.695450,60.962231 
     C66.210258,60.922249 66.017220,60.894951 65.824188,60.867653 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M63.980267,21.990501 
     C63.542522,21.990301 63.104774,21.990101 62.338974,21.987717 
     C62.007633,21.596291 62.004349,21.207048 62.003868,20.528374 
     C62.006676,20.238943 62.097271,19.869221 62.419640,19.690596 
     C63.162441,19.619373 63.582874,19.726776 64.003716,20.194115 
     C63.996174,21.032869 63.988220,21.511684 63.980267,21.990501 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M66.737808,51.167641 
     C66.957764,51.746872 66.943001,52.491413 66.774490,53.579651 
     C66.581528,53.059883 66.542313,52.196419 66.737808,51.167641 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M58.692204,47.811436 
     C58.994686,48.095871 58.996754,48.277802 58.999184,48.732716 
     C58.136360,48.823509 57.273178,48.641319 56.518658,48.160530 
     C56.794292,47.770870 56.961262,47.679806 57.128231,47.588737 
     C57.549416,47.628803 57.970604,47.668869 58.692204,47.811436 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M30.736464,46.951118 
     C31.084476,46.938034 31.163342,47.301071 31.173914,47.486259 
     C30.710367,47.793941 30.236250,47.916435 29.392834,48.026939 
     C28.998112,47.840889 28.972691,47.666832 28.971947,47.229797 
     C29.460566,46.965946 29.924509,46.965076 30.736464,46.951118 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M33.112663,47.769913 
     C32.815514,47.635166 32.866707,47.187195 32.943604,46.973854 
     C33.020500,46.760517 33.468182,46.853397 33.695621,46.841690 
     C34.155003,46.930199 34.386944,47.030415 34.938034,47.336601 
     C34.641396,47.663269 34.025600,47.783966 33.112663,47.769913 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M54.693985,37.111961 
     C54.948666,37.172306 54.995464,37.342060 54.975239,37.767250 
     C54.908218,38.022682 54.464424,38.015305 54.242706,38.018532 
     C54.081665,37.700119 54.236702,37.433319 54.693985,37.111961 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M51.284798,20.851112 
     C51.033497,21.013016 50.795109,20.982113 50.212479,20.841576 
     C50.169506,20.532351 50.470779,20.332762 51.045143,20.202774 
     C51.318241,20.272375 51.297703,20.658304 51.284798,20.851112 
    z"></path><path fill="#178CD7" opacity="1.000000" stroke="none" d="
    M48.572739,20.985126 
     C48.841530,20.931992 48.764343,21.380993 48.729515,21.606052 
     C48.446804,21.727518 48.190914,21.650585 47.858398,21.414064 
     C47.961174,21.164627 48.132561,21.101442 48.572739,20.985126 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M39.696114,60.993881 
     C39.947124,61.062485 39.964592,61.529564 39.738571,61.676613 
     C39.292042,61.683567 39.150799,61.486603 39.088829,61.232765 
     C39.088829,61.232765 38.982670,61.011612 38.965977,60.889347 
     C39.114559,60.819817 39.279831,60.872547 39.696114,60.993881 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M55.736076,34.150169 
     C55.856415,34.353287 55.731064,34.703568 55.359673,35.203735 
     C55.239220,35.001530 55.364799,34.649433 55.736076,34.150169 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M39.073826,47.957203 
     C39.073826,47.957203 38.859097,47.632458 38.774384,47.457512 
     C38.689671,47.282570 38.880169,47.109653 39.110451,47.049793 
     C39.340733,46.989933 39.617802,47.010487 39.617802,47.010487 
     C39.617802,47.010487 39.949154,47.056995 40.109955,47.010269 
     C40.015182,47.208103 39.759602,47.452667 39.270912,47.854065 
     C39.037800,48.010895 39.073826,47.957203 39.073826,47.957203 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M56.964314,47.427540 
     C56.961262,47.679806 56.794292,47.770870 56.342552,47.873863 
     C56.057789,47.885788 55.989056,47.494232 55.949692,47.299603 
     C56.227203,47.047493 56.523895,47.101280 56.964314,47.427540 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M32.078056,48.161335 
     C32.151604,47.927650 32.315315,47.866394 32.731308,47.876690 
     C32.711800,48.076748 32.440010,48.205257 32.078056,48.161335 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M39.037056,47.980568 
     C38.943443,47.979622 38.850571,48.008999 38.915764,47.997791 
     C39.073826,47.957203 39.037800,48.010895 39.037056,47.980568 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M66.079224,48.213921 
     C66.338478,48.103706 66.714371,48.215919 67.058693,48.647091 
     C66.750038,48.789482 66.472954,48.612915 66.079224,48.213921 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M29.187742,58.258728 
     C29.209887,58.019096 29.608017,58.015266 29.807117,58.017502 
     C29.940891,58.290539 29.811081,58.525738 29.452682,58.844505 
     C29.288578,58.963676 29.165598,58.498356 29.187742,58.258728 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M67.495102,20.131748 
     C67.884666,20.413368 67.898941,20.583160 68.085907,20.942507 
     C68.258614,21.132061 68.157860,21.555063 67.839066,21.865215 
     C67.326851,22.447918 67.133438,22.720467 66.940018,22.993015 
     C66.147995,22.741667 65.355972,22.490318 64.272110,22.114737 
     C63.988220,21.511684 63.996174,21.032869 64.003716,20.194115 
     C65.042137,19.896093 66.080978,19.958010 67.495102,20.131748 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M68.127121,25.591766 
     C67.878845,25.992937 67.711395,25.993429 67.293274,25.989916 
     C67.022972,25.237673 67.003334,24.489431 66.961853,23.367104 
     C67.133438,22.720467 67.326851,22.447918 67.746445,22.061922 
     C68.051071,23.029345 68.129501,24.110216 68.127121,25.591766 
    z"></path>
    
    <path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M53.577045,67.013519 
     C53.965176,68.068481 53.978046,69.133743 53.978867,70.598358 
     C53.007286,70.376678 52.047756,69.755646 51.088226,69.134605 
     C51.792747,68.431007 52.497269,67.727402 53.577045,67.013519 
    z"></path><path fill="#3b5998" opacity="1.000000" stroke="none" d="
    M53.584450,76.013947 
     C53.975178,77.394432 53.980717,78.786591 53.963993,80.596634 
     C52.978867,80.382698 52.016006,79.750893 51.053146,79.119087 
     C51.768517,78.087936 52.483887,77.056778 53.584450,76.013947 
    z"></path>
    
    </svg>
    </>)
}
else if(props.icon==='google'){
  return(<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" viewBox="0 0 182 195" enable-background="new 0 0 182 195" space="preserve"><path fill="#E74436" opacity="1.000000" stroke="none" d="
    M86.043785,10.147879 
    C87.006790,10.028565 87.627197,10.033405 88.884048,10.031217 
    C90.681404,9.948205 91.842300,9.872222 93.332657,9.875093 
    C94.107468,9.900771 94.552818,9.847597 95.368118,9.880655 
    C96.492867,9.935600 97.247650,9.904312 98.002434,9.873025 
    C111.505150,10.980084 124.405602,14.168982 136.802948,21.276619 
    C138.116028,21.813381 138.895004,22.016739 139.722641,22.204994 
    C139.771301,22.189894 139.741302,22.287216 139.776123,22.629906 
    C142.974518,25.387299 146.266907,27.658081 149.223679,30.303898 
    C150.117310,31.103540 150.080704,32.942741 150.470184,34.305763 
    C150.470184,34.305767 150.429825,34.421852 150.116547,34.519669 
    C149.364243,35.175842 148.925232,35.734203 148.486237,36.292564 
    C148.486237,36.292568 148.408676,36.408119 148.078720,36.546673 
    C147.168396,37.453575 146.588028,38.221924 146.007645,38.990273 
    C144.354813,40.433472 142.701965,41.876675 140.618378,43.745102 
    C139.092392,45.368946 137.997162,46.567570 136.602020,47.903767 
    C135.111130,49.326729 133.920151,50.612122 132.525116,51.963600 
    C132.055939,52.197945 131.922028,52.439049 131.919296,52.752998 
    C130.247955,54.147209 128.576614,55.541420 126.503510,56.552910 
    C118.814880,48.071636 109.209435,45.097698 98.820351,44.037193 
    C97.848213,43.937962 96.737480,45.196445 95.691093,45.824543 
    C94.100273,45.178078 92.428116,43.839302 90.933861,44.014839 
    C86.434830,44.543373 82.005074,45.661480 76.905174,46.758751 
    C75.169281,47.302696 74.076515,47.637543 72.622192,47.998661 
    C69.086136,50.023293 65.911621,52.021652 62.392216,54.019279 
    C61.419231,54.753616 60.791138,55.488686 60.131279,56.178986 
    C60.099510,56.134212 60.205875,56.106987 59.934196,56.178604 
    C59.463829,56.554298 59.265141,56.858383 59.069588,57.117966 
    C59.072727,57.073463 59.155071,57.107815 58.792847,57.097954 
    C57.659241,58.112759 56.887856,59.137424 56.098495,60.120262 
    C56.080517,60.078442 56.171406,60.073090 55.905327,60.163536 
    C55.461731,60.571178 55.284214,60.888371 55.116348,61.153969 
    C55.125999,61.102371 55.213840,61.159863 54.837265,61.135529 
    C53.810455,62.200741 53.160217,63.290283 52.434792,64.197861 
    C52.359604,64.015900 52.473186,64.392883 52.090572,64.372147 
    C51.316555,65.376015 50.925152,66.400612 50.533749,67.425217 
    C50.533749,67.425217 50.402508,67.474838 50.105026,67.429092 
    C49.575272,67.974655 49.342995,68.565956 49.110718,69.157265 
    C49.110718,69.157265 49.074749,69.056091 48.833664,69.203278 
    C48.414284,69.964478 48.235981,70.578491 48.057678,71.192513 
    C48.057678,71.192513 48.021557,71.594078 47.589664,71.855797 
    C46.459202,74.074265 45.760635,76.031013 45.062065,77.987762 
    C45.062065,77.987762 45.155567,78.170273 44.722416,78.160599 
    C42.891850,77.335365 41.415447,76.629158 40.109993,75.686256 
    C32.361519,70.089691 24.652634,64.438316 16.862400,58.459476 
    C16.843739,57.413475 16.893118,56.713360 17.058262,55.773682 
    C17.483603,55.115314 17.793180,54.696507 18.365814,54.075809 
    C19.482344,52.587719 20.335819,51.301514 21.384237,49.886425 
    C21.815001,49.531010 21.947334,49.254883 21.985851,48.960789 
    C21.995527,48.992424 21.929996,48.983303 22.178478,48.869164 
    C22.563747,48.429626 22.700537,48.104225 22.844078,47.833641 
    C22.850830,47.888462 22.747028,47.850670 23.029432,47.775669 
    C23.431671,47.353355 23.551504,47.006042 23.686409,46.728313 
    C23.701479,46.797897 23.590809,46.708298 23.923199,46.668251 
    C24.468943,46.039280 24.682295,45.450359 24.916626,44.901390 
    C24.937607,44.941345 24.847582,44.934959 25.111309,44.836613 
    C25.524778,44.408833 25.674519,44.079399 25.835140,43.814819 
    C25.846022,43.879673 25.718815,43.846268 26.021809,43.766846 
    C26.562660,43.138519 26.800516,42.589607 27.019112,42.020493 
    C26.999847,42.000294 27.041962,42.036591 27.368103,41.986389 
    C28.105896,41.253468 28.517548,40.570744 28.950384,39.931534 
    C28.971571,39.975052 28.885841,39.930099 29.233574,39.915054 
    C29.992065,39.194672 30.402821,38.489342 30.846508,37.826717 
    C30.879440,37.869427 30.771629,37.866020 31.049885,37.796394 
    C31.498251,37.401417 31.668362,37.076061 31.852497,36.808395 
    C31.866520,36.866089 31.750683,36.839958 32.032837,36.780998 
    C32.490395,36.398361 32.665806,36.074684 32.827442,35.804451 
    C32.813667,35.857891 32.732731,35.782848 33.102844,35.827534 
    C34.265877,34.845779 35.058792,33.819336 35.878212,32.835075 
    C35.904720,32.877258 35.806419,32.893486 36.077370,32.823761 
    C36.539417,32.447739 36.730518,32.141445 36.926140,31.882080 
    C36.930668,31.929010 36.848240,31.883217 37.185585,31.896391 
    C37.881523,31.451403 38.240120,30.993238 38.630234,30.542194 
    C38.661751,30.549314 38.695843,30.604214 38.974770,30.632128 
    C39.480965,30.384279 39.708233,30.108517 40.029221,29.502436 
    C42.552780,27.175072 44.982620,25.178022 47.412464,23.180975 
    C59.360363,16.460638 71.951653,11.648633 86.043785,10.147879 
    z"></path><path fill="#35A653" opacity="1.000000" stroke="none" d="
    M99.014908,183.578293 
    C95.812767,183.920624 92.826866,183.919617 89.172043,183.686539 
    C86.009247,183.023773 83.515358,182.593079 80.769699,182.062347 
    C80.337898,181.976944 80.157875,181.991592 79.849091,181.873840 
    C79.370644,181.774872 79.020935,181.808273 78.434723,181.673126 
    C77.818108,181.461914 77.438011,181.419266 76.760017,181.248169 
    C75.390076,180.983932 74.318031,180.848145 73.243134,180.731537 
    C73.240273,180.750732 73.201797,180.745575 73.004288,180.511993 
    C72.207924,180.213638 71.609062,180.148865 70.689438,179.897263 
    C53.045959,174.578094 38.477928,165.219452 27.013512,151.211349 
    C23.823368,147.313385 20.963539,143.145081 17.952179,139.100800 
    C17.598742,137.779877 17.245306,136.458939 17.240940,134.659836 
    C26.690582,127.377815 35.791149,120.573967 44.891716,113.770111 
    C50.315933,127.149483 58.780472,137.799149 72.481102,144.110809 
    C72.854576,144.138641 72.814575,144.119431 72.914581,144.356354 
    C73.333557,144.669006 73.652527,144.744736 74.258995,145.000473 
    C75.609200,145.507996 76.671898,145.835510 77.734596,146.163010 
    C80.376778,146.894196 83.018959,147.625381 86.397293,148.539230 
    C89.752678,148.795959 92.371910,148.870041 94.991142,148.944122 
    C95.748451,148.978226 96.505768,149.012314 97.985565,149.037018 
    C99.352982,148.805176 99.997917,148.582703 100.642853,148.360229 
    C100.642853,148.360245 100.707153,148.509476 101.235695,148.556641 
    C104.916756,147.819290 108.069275,147.034805 111.211334,146.308472 
    C111.200874,146.366638 111.315407,146.395813 111.620255,146.424408 
    C112.283897,146.269730 112.642685,146.086456 113.357742,145.869263 
    C117.189041,144.163559 120.664062,142.491776 124.416458,141.010406 
    C128.969879,144.574371 133.189377,148.022873 137.537445,151.300873 
    C142.120346,154.755905 146.825989,158.048141 151.243774,161.663879 
    C148.301010,164.258377 145.592499,166.600525 142.883987,168.942657 
    C129.639877,177.698242 114.749283,181.424957 99.014908,183.578293 
    z"></path><path fill="#F6BB0B" opacity="1.000000" stroke="none" d="
    M44.837696,113.401871 
    C35.791149,120.573967 26.690582,127.377815 17.252724,134.338562 
    C16.244740,133.735352 15.574046,132.975220 14.634035,131.700378 
    C14.161335,130.775040 13.957953,130.364441 13.780382,129.713409 
    C13.661278,129.104568 13.398062,128.973511 12.882076,128.956863 
    C12.747607,128.833878 12.384066,128.859726 12.332539,128.543747 
    C12.162515,127.818413 12.044018,127.409058 11.918520,126.663574 
    C10.270144,115.550995 8.628768,104.774529 6.987392,93.998070 
    C7.059658,93.579849 7.131923,93.161636 7.299276,92.310791 
    C7.436553,91.755013 7.545116,91.604416 7.507787,91.514160 
    C7.416475,91.293350 7.257748,91.100426 7.126154,90.896278 
    C7.126154,90.896278 7.093384,90.890282 7.110301,90.894127 
    C7.212162,90.490669 7.297105,90.083359 7.336770,89.363174 
    C7.817862,86.253754 8.344231,83.457199 8.968174,79.944817 
    C11.687311,72.421112 14.308874,65.613235 16.930439,58.805367 
    C24.652634,64.438316 32.361519,70.089691 40.109993,75.686256 
    C41.415447,76.629158 42.891850,77.335365 44.646095,78.229080 
    C41.763767,89.862701 40.993805,101.433769 44.837696,113.401871 
    z"></path><path fill="#F0FAFE" opacity="1.000000" stroke="none" d="
    M177.001831,73.018158 
    C177.334366,76.071365 177.666901,79.124580 178.003998,82.597214 
    C178.005798,88.063812 178.003052,93.110992 178.003815,98.581467 
    C177.895447,103.453865 177.783569,107.902954 177.239594,112.317581 
    C176.569717,111.193977 176.136536,110.106819 176.125534,109.015411 
    C176.048111,101.338554 176.057632,93.660812 176.071701,85.605614 
    C175.964478,84.486923 175.824951,83.745972 175.721405,82.726776 
    C175.576294,81.943459 175.395218,81.438377 174.906998,80.625908 
    C147.753922,80.268646 120.908005,80.218773 94.062080,80.168884 
    C95.793098,79.446220 97.520020,78.106194 99.255768,78.094658 
    C120.905472,77.950874 142.556488,78.016273 164.207092,77.977982 
    C166.831268,77.973343 169.583313,78.098656 172.030014,77.354546 
    C173.467072,76.917496 174.418106,74.882347 176.044586,73.286942 
    C176.668228,73.011299 176.835022,73.014732 177.001831,73.018158 
    z"></path><path fill="#4285F2" opacity="1.000000" stroke="none" d="
    M137.122116,125.238152 
    C138.280746,121.570076 139.439392,117.902008 140.305084,114.000107 
    C138.246628,113.508553 136.482407,113.038338 134.715408,113.027710 
    C123.566010,112.960648 112.411453,112.810974 101.268341,113.084900 
    C96.611923,113.199364 94.667641,111.958862 94.901970,106.961700 
    C95.275742,98.991043 95.012436,90.990501 95.496025,82.513428 
    C119.410179,82.014542 142.846878,82.026222 166.283340,81.955170 
    C168.189774,81.949394 170.094498,81.377899 172.000015,81.069275 
    C172.423080,81.075104 172.846161,81.080925 173.614746,81.429123 
    C174.293030,82.532578 174.625778,83.293655 174.973465,84.366577 
    C175.003311,85.093979 175.018250,85.509537 175.015045,86.378578 
    C174.992493,93.377090 174.975800,99.922150 174.988861,106.467155 
    C174.995300,109.687340 175.056854,112.907417 175.091614,116.525040 
    C174.458145,120.281357 173.826538,123.640160 173.112152,127.365387 
    C172.741974,129.154678 172.454590,130.577545 172.167206,132.000412 
    C169.097198,137.420135 166.027206,142.839859 162.980789,148.627960 
    C162.679672,150.388535 162.354996,151.780746 161.661026,153.594604 
    C160.237473,155.352493 159.183182,156.688705 158.128891,158.024902 
    C156.644531,159.010010 155.160187,159.995132 153.113373,161.181595 
    C152.193283,161.392456 151.835648,161.401978 151.478012,161.411499 
    C146.825989,158.048141 142.120346,154.755905 137.537445,151.300873 
    C133.189377,148.022873 128.969879,144.574371 124.399879,140.849960 
    C124.105927,140.499084 124.092209,140.174606 124.350525,140.081207 
    C124.802139,139.689880 124.995438,139.391968 125.138161,139.120056 
    C125.087593,139.146057 125.165565,139.228836 125.469475,139.159668 
    C126.530273,138.405045 127.287163,137.719574 128.025177,137.015289 
    C128.006287,136.996460 128.023117,137.046738 128.281281,136.975967 
    C128.728363,136.614166 128.917267,136.323151 129.405716,135.896652 
    C131.205765,133.869675 132.706284,131.978180 134.456161,129.882996 
    C135.511063,128.198929 136.316589,126.718536 137.122116,125.238152 
    z"></path></svg>)
}
else if(props.icon==='home'){
  return(<>{props.ShowMenu==='home'?<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="green" className={props.className}><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"/></svg>:<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368" className={props.className}><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"/></svg>}</>)
}else if (props.icon==='category'){
  return (<>{props.ShowMenu==='category'?<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="green"  className={props.className}><path d="M80-140v-320h320v320H80Zm80-80h160v-160H160v160Zm60-340 220-360 220 360H220Zm142-80h156l-78-126-78 126ZM863-42 757-148q-21 14-45.5 21t-51.5 7q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7 50.5T813-204L919-98l-56 56ZM660-200q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM320-380Zm120-260Z"/></svg>:<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"  className={props.className}><path d="M80-140v-320h320v320H80Zm80-80h160v-160H160v160Zm60-340 220-360 220 360H220Zm142-80h156l-78-126-78 126ZM863-42 757-148q-21 14-45.5 21t-51.5 7q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7 50.5T813-204L919-98l-56 56ZM660-200q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM320-380Zm120-260Z"/></svg>}</>)
}
else if(props.icon==='Mcart'){
  return (<>{props.ShowMenu==='Mcart'?<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="green"  className={props.className}><path d="M292-120q-38 0-65-27.5T200-213v-371l-73-176H40v-80h141l66 160h591q23 0 35 19t1 39L760-399q51 8 85.5 47t34.5 92q0 58-40.5 99T741-120q-59 0-99.5-41T601-260q0-20 5-37t14-33l-131-12-120 180q-13 20-33.5 31T292-120Zm382-285 99-195H280l50 120q8 20 25.5 33.5T396-431l278 26ZM293-201q2 0 9-5l97-144q-49-5-77-23.5T280-412v200q0 5 4 8t9 3Zm447 1q26 0 43-17.5t17-42.5q0-26-17-43t-43-17q-25 0-42.5 17T680-260q0 25 17.5 42.5T740-200Zm-66-205-278-26 278 26Z"/></svg>:<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"  className={props.className}><path d="M292-120q-38 0-65-27.5T200-213v-371l-73-176H40v-80h141l66 160h591q23 0 35 19t1 39L760-399q51 8 85.5 47t34.5 92q0 58-40.5 99T741-120q-59 0-99.5-41T601-260q0-20 5-37t14-33l-131-12-120 180q-13 20-33.5 31T292-120Zm382-285 99-195H280l50 120q8 20 25.5 33.5T396-431l278 26ZM293-201q2 0 9-5l97-144q-49-5-77-23.5T280-412v200q0 5 4 8t9 3Zm447 1q26 0 43-17.5t17-42.5q0-26-17-43t-43-17q-25 0-42.5 17T680-260q0 25 17.5 42.5T740-200Zm-66-205-278-26 278 26Z"/></svg>}</>)
}
else if(props.icon==='orders'){
  return (<>{props.ShowMenu==='orders'?<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="green"  className={props.className}><path d="M480-120q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q82 0 155.5 35T760-706v-94h80v240H600v-80h110q-41-56-101-88t-129-32q-117 0-198.5 81.5T200-480q0 117 81.5 198.5T480-200q105 0 183.5-68T756-440h82q-15 137-117.5 228.5T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z"/></svg>:<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"  className={props.className}><path d="M480-120q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q82 0 155.5 35T760-706v-94h80v240H600v-80h110q-41-56-101-88t-129-32q-117 0-198.5 81.5T200-480q0 117 81.5 198.5T480-200q105 0 183.5-68T756-440h82q-15 137-117.5 228.5T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z"/></svg>}</>)
}
else if(props.icon==='makerPointer'){
  return (<svg fill="none" height="22" viewBox="0 0 18 22" width="18" xmlns="http://www.w3.org/2000/svg" className={props.className} ><path d="M9 0C4.02949 0 0 3.99849 0 8.93076C0 13.4051 5.60746 19.292 7.97051 21.5743C8.54929 22.1321 9.4644 22.1438 10.0544 21.5971C12.4316 19.3982 18 13.7335 18 8.93028C17.9995 3.99849 13.9705 0 9 0ZM9 11.8325C6.94492 11.8325 5.27994 10.1803 5.27994 8.14154C5.27994 6.10276 6.94492 4.4501 9 4.4501C11.0546 4.4501 12.7191 6.10227 12.7191 8.14154C12.7191 10.1808 11.0546 11.8325 9 11.8325Z" fill="green"></path>
  </svg>)
}
else if(props.icon==='ArowLeft'){
  return (<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M560-280 360-480l200-200v400Z"/></svg>)
}else if(props.icon==='Chk'){
  return (<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M400-304 240-464l56-56 104 104 264-264 56 56-320 320Z"/></svg>)
}else if(props.icon==='cross'){
  return <svg xmlns="http://www.w3.org/2000/svg" height={props.height} viewBox="0 -960 960 960" width={props.width} fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
}
else if(props.icon==='MapIc'){
  return <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#5f6368"><path d="m600-120-240-84-186 72q-20 8-37-4.5T120-170v-560q0-13 7.5-23t20.5-15l212-72 240 84 186-72q20-8 37 4.5t17 33.5v560q0 13-7.5 23T812-192l-212 72Zm-40-98v-468l-160-56v468l160 56Zm80 0 120-40v-474l-120 46v468Zm-440-10 120-46v-468l-120 40v474Zm440-458v468-468Zm-320-56v468-468Z"/></svg>
}else if(props.icon==='GPS'){
  return <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#5f6368"><path d="M519-82v-80q42-6 81.5-23t74.5-43l58 58q-47 37-101 59.5T519-82Zm270-146-56-56q26-33 42-72.5t22-83.5h82q-8 62-30.5 115.5T789-228Zm8-292q-6-45-22-84.5T733-676l56-56q38 44 61.5 98T879-520h-82ZM439-82q-153-18-255.5-131T81-480q0-155 102.5-268T439-878v80q-120 17-199 107t-79 211q0 121 79 210.5T439-162v80Zm238-650q-36-27-76-44t-82-22v-80q59 5 113 27.5T733-790l-56 58ZM480-280q-58-49-109-105t-51-131q0-68 46.5-116T480-680q67 0 113.5 48T640-516q0 75-51 131T480-280Zm0-200q18 0 30.5-12.5T523-523q0-17-12.5-30T480-566q-18 0-30.5 13T437-523q0 18 12.5 30.5T480-480Z"/></svg>}
}

export default IconCall
