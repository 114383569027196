import React from 'react'
import Agent from '../deliver/sent'
import '../style-item/profile.css'
import CryptoJS from 'crypto-js';
import IconCall from '../icons/IconCall';

function UserProfile(props) {
   
  const secretPass1 = "sdjkljlsdlkJKjksdlkseOEjd";
  const secretPass2 = "kldsklfjddfdsdlkdfkkldfjkf";
  const secretPass4 = "fjdkdfkljgldfguitytlkseOEjd";
 
  const secretPass5 = "sdjkljlsdldfdrtdfdgfgghfEjd";
    
  return (
    <div className='y6Z-HFp  My-profile'>
        <div className='k-1652'>
            <div className='user(Maped){}'>
                <div onClick={()=>props.SignedCheck(false)}><IconCall icon={'close'} style={{zIndex:'1'}} ></IconCall></div>
                <div className='kljsdf-fs-s;l;sfdsdf(Null)Maped'>
                    <div className='dlkpeP'>
                        <div className='nameDetails'>
                            <div className='uOName'><Agent.Consumer>{prfUn=>(<>{CryptoJS.AES.decrypt(sessionStorage.getItem('uDnE'), secretPass2).toString(CryptoJS.enc.Utf8)}</>)}</Agent.Consumer></div>
                            <div className='uJlkKda'><Agent.Consumer>{prfULd=>(<>{CryptoJS.AES.decrypt(sessionStorage.getItem('uDeE'), secretPass1).toString(CryptoJS.enc.Utf8)}</>)}</Agent.Consumer></div>
                        </div>
                        <div className='Ljkdse'>
                            <button className='Kduievr'>Edit</button>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div>Orders</div>
            </div>
        </div>
      
    </div>
  )
}

export default UserProfile
