import React,{useState,createContext,useContext,useId} from 'react'
import '../style-item/App.css'
import SignIn from './Signinuser'
import { useMediaQuery } from 'react-responsive';
import Top from './top'
import Menu from './menu'
import Locpermiss from './Locpermiss'
import SPL from './SearchPL';
import Mymap from './mymap';
import Agent from '../deliver/sent'
import PopupLocation from './PopupLocation';

function App(props) {
    
  const [PopResetLocation,ShowPopResetLocation]=useState(false)
  const [Map,ShowMap]=useState(false)
  const [CustomPeramiter,SetCustomPeramiter]=useState(null)
  const [SearchBar,ShowSearchBar]=useState(false)
  const [GetMyLocation,SetMyLocation]=useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const [isLocationEnable,StatusLocation]=useState(sessionStorage.getItem('LocationUpdated')?true:false)
  const [Signed,SignedCheck]=useState(props.loginReq?props.loginReq:false)
  const ScreenContext=createContext(null)
  const [ShowMenu,SetMenu]=useState('home')
  const setStoreItem = {
    map: [Map,ShowMap],
    peramiter: [CustomPeramiter,SetCustomPeramiter],
    search: [SearchBar,ShowSearchBar],
    location: [GetMyLocation,SetMyLocation],
   locationStatus: [isLocationEnable,StatusLocation],
   user:[Signed,SignedCheck],
   menu:[ShowMenu,SetMenu]
  }
  let cookies = document.cookie;
  console.log(cookies)
  const Anomyous=()=>{

  
  if(sessionStorage.getItem('timeOfLastActive')){(sessionStorage.getItem('timeOfLastActive')>(sessionStorage.getItem('timeOfLastActive')+600000))?sessionStorage.getItem('timeOfLastActive'):sessionStorage.clear()}
  return (<></>)}
return (
    <>
   
   





  
  {isMobile &&
    <div className='JKsOer'>
  <Top ShowPopResetLocation={ShowPopResetLocation}  ShowMap={ShowMap} SearchBar={SearchBar} ShowSearchBar={ShowSearchBar} Signed={Signed} SetCustomPeramiter={SetCustomPeramiter} GetMyLocation={GetMyLocation} SetMyLocation={SetMyLocation}  isLocationEnable={isLocationEnable} StatusLocation={StatusLocation} SignedCheck={SignedCheck}/>
  {!isLocationEnable&&<Locpermiss ShowMap={ShowMap} StatusLocation={StatusLocation} CustomPeramiter={CustomPeramiter} GetMyLocation={GetMyLocation} SetMyLocation={SetMyLocation}/>}
  {SearchBar&&<SPL ShowSearchBar={ShowSearchBar}/>}
  {Map&&<Mymap ShowMap={ShowMap} StatusLocation={StatusLocation}/>}
  {PopResetLocation&&<PopupLocation SetMyLocation={SetMyLocation} ShowPopResetLocation={ShowPopResetLocation} ShowMap={ShowMap}/>}
     <Menu ShowMenu={ShowMenu} SetMenu={SetMenu}/>
  
    </div>}
    {isDesktop &&<> <div className='Dex_y1'><div className='Dex_y2'>
      <Top ShowPopResetLocation={ShowPopResetLocation} ShowSearchBar={ShowSearchBar}  Signed={Signed} SetCustomPeramiter={SetCustomPeramiter}  GetMyLocation={GetMyLocation} SetMyLocation={SetMyLocation}   isLocationEnable={isLocationEnable} StatusLocation={StatusLocation}  SignedCheck={SignedCheck}/>
    </div> {!isLocationEnable&&<Locpermiss ShowMap={ShowMap} StatusLocation={StatusLocation} CustomPeramiter={CustomPeramiter} GetMyLocation={GetMyLocation} SetMyLocation={SetMyLocation}/>}
      {SearchBar&&<SPL/>}
      {Map&&<Mymap ShowMap={ShowMap} StatusLocation={StatusLocation}/>}
        {Signed && <SignIn Signed={Signed} SignedCheck={SignedCheck}/>}
        {PopResetLocation&&<PopupLocation  ShowPopResetLocation={ShowPopResetLocation} SetMyLocation={SetMyLocation} ShowMap={ShowMap}/>}
        <Menu ShowMenu={ShowMenu} SetMenu={SetMenu}/>
        </div></>}
     


   </>
    
  )
}

export default App
