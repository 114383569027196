
import React, { useRef, useEffect } from 'react';

const Autocomplete = ({ onPlaceSelected }) => {
    
  const inputRef = useRef(null);
 
 
  useEffect(() => {
   const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      onPlaceSelected(place);
    });
  }, []);

  return <input ref={inputRef}  id="pac-input" autoFocus class="controls" type="text" style={{zIndex: '1500000002'}} placeholder="Search Area Name" />;
};

export default Autocomplete;
