import React,{useCallback, useEffect,useMemo,useRef,useState} from 'react'
import '../style-item/mymap.css'
import IconCall from '../icons/IconCall';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import Autocomplete from './placeSearch';
import { useMediaQuery } from 'react-responsive';


export default function Mymap(props){
  
const mapStyle= [
  {
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "color": "#d9a412"
      },
      {
        "weight": 6.5
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#523735"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#c9b2a6"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#dcd2be"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ae9e90"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#93817c"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "weight": 8
      }
    ]
  },
  {
    "featureType": "poi.government",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#a5b076"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#447530"
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.sports_complex",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#fdfcf8"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f8c967"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#e9bc62"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e98d58"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#db8555"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#806b63"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8f7d77"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#ebe3cd"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels",
    "stylers": [
      {
        "color": "#260a3d"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "color": "#002a57"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#388ce5"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#260a3d"
      },
      {
        "weight": 5
      }
    ]
  }
]

  const [MarkerShow,SetMarker]=useState(true)
const [LocationShow,SetLocation]=useState(false)
  const SetMyLocation=props.SetMyLocation
  const miniMum = useMediaQuery({ query: '(max-width: 500px)' });
  const maxiMum = useMediaQuery({ query: '(min-width: 501px)' });
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [Latitude,SetLatitude]=useState(23.292362381456865)
  const [Longitude,SetLongitude]=useState(91.54718399047852)
  const [MapResponse,SetMapResponse]=useState(false)
  const [Address1,SetAddress1]=useState(null)
  const [Address2,SetAddress2]=useState(null)
  const defaultProps = {
  
    center: {
     lat:Latitude,
          lng:Longitude
    },
    zoom: 15
  };
 
  const latLng=(e)=>{
    
    SetMarker(true)
    SetLatitude(e.lat)
    SetLongitude(e.lng)
    axios.get('./getmylocation',{
      params: {lat:e.lat,
      lng:e.lng}}) .then(response => {
SetAddress1(response.data.results[0].address_components[2].long_name)
SetAddress2(response.data.plus_code.compound_code)
SetLocation(true)
localStorage.setItem('Lodsjdfoer', JSON.stringify(response))
       })  .catch(error => {
     sessionStorage.setItem('error',error)})
  
   /* axios.get('./getmylocation/',{
      params: { lat:Latitude,
        lng:Longitude}})
        .then(response => { 
          
           }
        )
        
        .catch(error => {
       sessionStorage.setItem('error',error)
        })*/
  }
  function setAsMyLocation(){
    
          sessionStorage.setItem('timeOfLastActive',new Date()/1)
          sessionStorage.setItem('userLocation',Address1);
          sessionStorage.setItem('userLocationPlusCode',Address2);
          sessionStorage.setItem('LocationUpdated','MAP');
         props.StatusLocation(true);
           props.ShowMap(false);
        SetMyLocation(true);
      
        }
           
  const handlePlaceSelected = (place) => {
    const location = place.geometry.location;
    SetLatitude(location.lat()) 
    SetLongitude(location.lng());
  console.log(location)
  SetMarker(true)
 
  };
  return (
    <>
   
    <div className='Map'>
    <div className='MapHeader'>
            <div className='HeaderItem'>
          
              {miniMum&&<div className='HeaderIcon' onClick={()=>props.ShowMap(false)} ><IconCall icon={'ArowLeft'}/></div>}
          <Autocomplete onPlaceSelected={handlePlaceSelected}/>
          {maxiMum&&<div className='HeaderIcon' onClick={()=>props.ShowMap(false)} ><IconCall icon={'cross'} height={25} width={25}/></div>}
              </div></div>
    <div id="map" class="inputListener" style={{height: '90%', width: '100%', position: 'relative', overflow: 'hidden',padding:'0 16px'}}>
 
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCRP5bkvEVfRWqHDN1ZUTzLl8XKcLTWKsA" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        style={{
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "red"
            }
          ]
        }}
        maxZoom={15}
        options={{
          scrollwheel: false,
          streetViewControl: false,
          maxZoom:15,
          styles: mapStyle,zoomControl:false
      }}
        onClick={(e)=>latLng(e)}
        distanceToMouse={(x)=>console.log(x)}
    onZoomAnimationStart={(y)=>SetMarker(false)}
      >

         
      {MarkerShow&&<AnyReactComponent
          lat={Latitude+0.001}
          lng={Longitude-0.0003}
      
          text={<IconCall icon={'pointer'} height={44} width={44}/>}
          
        />}</GoogleMapReact>
      
  {LocationShow &&
  <div className='kgyyu124'><div  children='FatchLocation'>
    <div className='locationF7'>
     
      <div className='hKdkw4'>
        <div className='s1429554'>
          <div className='JHDxnus'>
            <span className='dFnfJ-Y'>Your Location:</span>
          </div>
          <div onClick={()=>SetLocation(false)} className='keJ-dHh'>
            <IconCall icon={'close'}/>
          </div>
        </div>
      </div>
      <div className='s_diDuN'></div>
    </div>
    <div>
      <h1 className='h2Sgn sUI-ee'>{Address1}</h1>
      <h1 className='h2Sgn sUI-ee'>{Address2}</h1>
    </div>
    <div className='JDkr_14'>
    <input type="button"onClick={setAsMyLocation} id="SetLocationBTN" value="Confirm"/>
    </div></div>
  </div>}
    </div></div>
    
     </>
  );
}



/*
function Mymap(props) {
   const [MapResponse,SetMapResponse]=useState(null)
  const [MapResponse2,SetMapResponse2]=useState(null)
function setAsMyLocation(){
  sessionStorage.setItem('timeOfLastActive',new Date()/1)
  localStorage.setItem('fetchStatusOk',true)
  sessionStorage.setItem('userLocation',MapResponse)
  sessionStorage.setItem('userLocationPlusCode',MapResponse2)
  
       props.StatusLocation(true)
 
}

  const [getMyLocation,SetDeLocation]=useState(false)
  const refLng=useRef();
  const reflat=useRef();
 
   function SetLoad(){
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = "https://redinvest.cloud/selectmap/own.js";
            document.head.appendChild(script);
  }
  const MemoLoc= useMemo(() => SetLoad,[])
function getMylocationLonLat(){
 console.log()
    axios.get('./getmylocation/',{
      params: {lat:reflat.current.value,
      lng:refLng.current.value}})
        .then(response => { 
          SetMapResponse(response.data.results[0].address_components[2].long_name)
          SetMapResponse2(response.data.plus_code.compound_code)
           }
        )
        
        .catch(error => {
       sessionStorage.setItem('error',error)
        });}


 
      
        function SearchHeader(){
          return (  <div className='MapHeader'>
            <div className='HeaderItem'>
          
              <div className='HeaderIcon' onClick={()=>props.ShowMap(false)} ><IconCall icon={'ArowLeft'}/></div>
              
         
              <input /><MemoLoc/></div></div>)
        }
function MapLat(){
  
  return (
  
  <div className='kgyyu124'><div  children='FatchLocation'>
    <div className='locationF7'>
     
      <div className='hKdkw4'>
        <div className='s1429554'>
          <div className='JHDxnus'>
            <span className='dFnfJ-Y'>Your Location:</span>
          </div>
          <div className='keJ-dHh'>
            <IconCall icon={'close'}/>
          </div>
        </div>
      </div>
      <div className='s_diDuN'></div>
    </div>
    <div>
      <h1 className='h2Sgn sUI-ee'>{MapResponse}</h1>
      <h1 className='h2Sgn sUI-ee'>{MapResponse2}</h1>
    </div>
    <div className='JDkr_14'>
    <input type="button" onClick={setAsMyLocation} id="SetLocationBTN" value="Set Location"/>
    </div></div>
  </div>)
}
function condiTMapClick(){
  SetDeLocation(true);
   getMylocationLonLat()
}
return (
    <>

    <div className='Map'>
  <SearchHeader/>
      <div id="map" className='inputListener' onClick={condiTMapClick} style={{height: '90%', width: '100%'}}></div>
{getMyLocation&& <MapLat/>}
      </div>
      <input className="Longitude componenetHide" id='Longitude' ref={refLng} ></input>
      <input className="Latitude componenetHide" id='Latitude' ref={reflat} ></input></>

    
  )


}
export default Mymap*/
